// @flow
import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout, { SEO } from '../components/Layout'

export default function Projekte({ data }: any) {
  const {
    allMarkdownRemark: { edges },
  } = data

  return (
    <Layout>
      <SEO title="Projekte" />

      <div className="content-aside">
        <h1>Ausgewählte Projekte:</h1>
      </div>

      <div className="content">
        <ul>
          {edges
            .sort((a, b) => {
              const nameExtractor = /^.*[\\/]/
              const collator = new Intl.Collator(undefined, {
                numeric: true,
                sensitivity: 'base',
              })
              const filenameA = a.node.fileAbsolutePath.replace(
                nameExtractor,
                ''
              )
              const filenameB = b.node.fileAbsolutePath.replace(
                nameExtractor,
                ''
              )

              return collator.compare(filenameA, filenameB)
            })
            .filter(
              ({
                node: {
                  frontmatter: { slug },
                },
              }) => slug
            )
            .map(({ node: { id, frontmatter: { slug, title, location } } }) => (
              <li key={id}>
                <Link to={`/projekte/${slug}`}>
                  {title} • {location}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          fileAbsolutePath
          frontmatter {
            slug
            title
            location
          }
        }
      }
    }
  }
`
